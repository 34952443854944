button,
.button {
    display: table;
    padding: $btn-padding;
    transition: 0.2s ease-in-out;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    letter-spacing: 1.2px;
    line-height: inherit;
    text-decoration: none;
    border: 1px solid $primary;
    color: inherit;

    &:hover {
        transition: 0.2s ease-in-out;
    }

    &:focus {
        outline: 0;
    }

    &.primary {
        background: $primary;
        color: $white;

        &:hover {
            background-color: $white;
            color: $primary;
            font-weight: 600;
            border: 2px solid $primary;
            box-shadow: 0 7px 14px 0 rgba(0,0,0,0.2);
            transform: translateY(-2px);
        }
    }

    &.border {
        background: transparent;
        box-shadow: inset 0 0 0 1px $primary;
        border: none;
        color: $primary;

        &:hover {
            background: $primary;
            color: $white;
        }
    }
}
