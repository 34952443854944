@import "~normalize.css/normalize.css";
@import "partials/_variables.scss";
@import "partials/_accordion.scss";
@import "partials/_slider.scss";
@import "partials/_columns.scss";
@import "partials/_buttons.scss";
@import "partials/_forms.scss";
@import "partials/_messages.scss";
@import "partials/_breakpoints.scss";

@import "partials/_cart.scss";

//Font
@import url("https://use.typekit.net/njy0bwi.css");

$max-width: 1440px;
$min-width: 320px;

// Global Styles
*,
*:before,
*:after {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

body,
html {
    font-family: $font;
    color: $dark-grey;
    position: relative;
    text-align: center;
    -webkit-overflow-scrolling: touch;
    * {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
}

// Error pages
.text-error {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
    padding: 0.5em;
}

.container {
    padding: 0 40px;
    width: 100%;
    max-width: $max-width;
    min-width: $min-width;
    margin: 0 auto;

    @include xs {
        padding: 0 20px;
    }
}

.row {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;

    @include md {
        flex-direction: column;
    }

    &.start {
        align-items: flex-start;
    }

    &.half-col {
        fieldset {
            width: 100%;
            max-width: 50%;

            @include xs {
                max-width: 100%;
            }
        }
    }
}

nav {
    &.breadcrumbs {
        text-align: left;
        ul {
            display: inline-block;
            li {
                display: inline-block;
                &:not(:last-child)::after {
                    content: ">";
                    display: inline-block;
                }
            }
        }
    }
}
body > header {
    nav {
        ul {
            li {
                list-style: none;
                display: inline-block;
            }
        }
        // &.user {
        //     .cart {
        //         box-shadow: 5px 5px 5px rgba($off-black, 0.2);
        //         .cart-items {
        //             padding-top: 10px!important;
        //             padding-bottom: 0!important;
        //             .cart-item {
        //                 padding: 0 0;
        //                 .item-details {
        //                     flex: 1 1 auto;
        //                 }
        //                 .item-qty {
        //                     flex: 0 0 auto;
        //                 }
        //                 .item-price {
        //                     flex: 1 1 auto;
        //                     text-align: right;
        //                 }
        //                 .item-remove {
        //                     flex: 0 0 auto;
        //                     margin-right: -10px;
        //                     margin-left: 10px;
        //                 }
        //             }
        //         }
        //         .totals {
        //             font-weight: 600;
        //             padding:  5px 10px 5px 5px;
        //             border-top: 1px dotted $off-black;
        //         }
        //     }
        // }
    }
}

main {
    &.account,
    &.checkout {
        ul.sections {
            justify-content: center;
            li {
                display: block;
                padding: 0 10px;
                a {
                    display: block;
                    border: 1px solid $black;
                    border-radius: 5px;
                    padding: 10px 20px;
                }
                &.current {
                    a {
                        background: $black;
                        color: $white;
                    }
                }
            }
        }
        form {
            max-width: 600px;
            flex: 1 0 auto;
        }
        &.address,
        &.cart {
            form {
                max-width: 980px;
                flex: 1 0 auto;
            }
        }
    }
    &.checkout {
        &.cart {
            .cart-items {
                & > li.cart-item {
                    padding: 5px 10px;
                    input {
                        margin: 0;
                    }
                    .item-image {
                        width: 40px;
                        height: 40px;
                        flex: 0 0 auto;
                    }
                    .item-details {
                        text-align: left;
                        justify-self: flex-start !important;
                        flex: 1 0 auto;
                        padding: 0 10px;
                        width: calc((100% - 200px) / 2);
                    }
                    .item-qty {
                        flex: 0 0 auto;
                        padding: 0 10px;
                        width: calc((100% - 200px) / 2);
                        input {
                            display: inline-block;
                            width: 8rem;
                        }
                    }
                    .item-price {
                        flex: 0 0 auto;
                        text-align: right;
                        max-width: 8rem;
                        padding: 0 10px;
                    }
                    .item-remove {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }
            .totals {
                font-weight: 600;
                padding: 0 20px;
                span {
                    display: inline-block;
                    min-width: 8rem;
                }
            }
        }
        &.shipping,
        &.payment {
            & > .container > .row {
                justify-content: center;
                align-items: flex-start;
            }
            form {
                margin: 0;
                fieldset {
                    &.shipping-methods {
                        padding: 20px;
                        label {
                            padding: 10px;
                            display: flex;
                            align-items: center;
                            input {
                                margin-right: 1em;
                            }
                            span {
                                font-weight: 600;
                                display: inline-block;
                                margin-left: 1em;
                            }
                        }
                    }
                    &.buttons {
                        display: flex;
                        justify-content: center;
                        button,
                        .button {
                            margin: 2em 10px 0;
                        }
                    }
                }
                .payment-method {
                    margin-top: 20px;
                    padding-top: 20px;
                }
                .message {
                    padding: 20px 10px;
                    text-align: left;
                    ul {
                        margin: 1em 2em;
                    }
                }
            }
            .totals {
                border-radius: 5px;
                padding: 40px 40px;
                max-width: 330px;
                margin: 0 10px;
                flex: 1 0 auto;
                text-align: left;
                ul {
                    margin: 1em 0;
                    li {
                        list-style: none;
                        display: flex;
                        justify-content: space-between;
                        font-weight: 600;
                        padding: 0.5em 5px;
                        &.total {
                            font-weight: 700;
                        }
                    }
                }
            }
        }
    }
}

main {
    &.products {
        ul.row {
            justify-content: flex-start;
            li.product {
                list-style: none;
                border: 1px solid $black;
                overflow: hidden;
                width: calc(25% - 20px);
                margin: 10px;
                a {
                    &.image {
                        display: block;
                        width: 100%;
                        padding-top: 100%;
                        background-size: contain;
                    }
                }
                div {
                    text-align: left;
                    padding: 20px 40px;
                }
            }
        }
        &.single {
        }
    }
}

// Flatpickr
.flatpickr-wrapper {
    @apply w-full;
}

.flatpickr-calendar.open {
    z-index: 1000 !important;
    filter: none !important;
}

.flatpickr-months {
    @apply mb-2 text-gray-800;
}

.flatpickr-next-month,
.flatpickr-prev-month {
    @apply text-gray-800;

    svg {
        fill: currentColor !important;
    }
}

.flatpickr-current-month span.cur-month:hover,
.numInputWrapper:hover {
    @apply bg-transparent;
}

.flatpickr-day {
    @apply border-0;

    &.today {
        @apply font-semibold;

        &:hover {
            color: inherit;
            @apply bg-gray-400;
        }
    }

    &:hover {
        @apply bg-gray-400;
    }

    &.selected {
        @apply bg-blue-600 text-white;

        &:hover,
        &.today:hover {
            @apply bg-blue-400 text-white;
        }
    }
}

.flatpickr-time {
    border: none !important;

    input,
    .flatpickr-am-pm,
    .flatpickr-time {
        background: none !important;
    }
}

.flatpickr-time input.flatpickr-hour,
.flatpickr-time input.flatpickr-minute,
.flatpickr-time input.flatpickr-second {
    @apply font-semibold;

    &::selection {
        background: none;
    }
}

// -- IgniteXcellence

// -- Header
header {
    .middle-header {
        width: 100%;
        background-color: $white;

        .row {
            @extend .container;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 20px 40px;

            @include md {
                flex-direction: row;
            }

            @include xs {
                padding: 1rem 20px;
            }
        }

        .logo {
            display: block;
            width: auto;
            height: 85px;

            @include md {
                height: 65px;
            }

            @include xs {
                height: 55px;
            }
        }

        .header-nav {
            position: relative;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            @include md {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            a {
                text-decoration: none;
            }

            ul {
                background-color: transparent;
                list-style: none;
                margin: 0;
                padding-left: 0;
                display: flex;

                @include md {
                    width: 100%;
                    flex-direction: column;
                    align-items: center;
                    margin: 1em auto;
                }

                li {
                    color: $dark-grey;
                    background: transparent;
                    display: block;
                    padding: 1em;
                    text-decoration: none;
                    transition-duration: 300ms;
                    margin: 0;

                    &:hover {
                        a {
                            color: $primary;
                        }
                    }

                    &.li-overlay {
                        margin-right: 1em;

                        &.small-pos {
                            position: relative;

                            &:hover {
                                a {
                                    color: $white;
                                }
                            }
                        }

                        &:hover {
                            background: $primary;
                            z-index: 4;

                            .categories-menu {
                                color: $white;

                                &:after {
                                    content: "";
                                    display: inline-block;
                                    margin-left: 0.5rem;
                                    width: 24px;
                                    height: 16px;
                                    vertical-align: middle;
                                    background-repeat: no-repeat;
                                    background-size: cover;
                                    background-position: center;
                                    background-image: url("/img/up-arrow.svg");
                                }
                            }
                        }
                    }

                    a {
                        color: $dark-grey;
                        font-size: 1.125rem;
                        font-weight: 500;

                        @include md {
                            color: $white;
                        }
                    }

                    > ul {
                        background: white;
                        visibility: hidden;
                        opacity: 0;
                        min-width: 250px;
                        position: absolute;
                        transition: all 0.5s ease;
                        margin-top: 0.15rem;
                        left: 1em;
                        top: 3em;
                        padding: 1em 1em;
                        display: none;
                        z-index: 200;
                        z-index: 100;
                        transition: all 300ms ease;

                        &.dropdown.small {
                            width: fit-content;
                            height: fit-content;
                            padding: 1em 1em 0 1em;

                            li {
                                padding: 0 0 1em 0;
                            }
                        }

                        a {
                            color: $black !important;
                            font-weight: 400;
                            display: block;
                            text-align: left;
                            width: 100%;

                            &:hover {
                                color: $primary !important;
                            }
                        }

                        > li {
                            padding: 0 1em 1em 0;
                            position: relative;
                            margin: 0;
                            width: fit-content;

                            &:hover {
                                background-color: transparent !important;
                            }

                            > ul {
                                background: white;
                                visibility: hidden;
                                opacity: 0;
                                min-width: 5rem;
                                position: absolute;
                                left: 100%;
                                top: -0.15rem;
                                display: none;

                                &:hover {
                                    visibility: visible;
                                    opacity: 1;
                                    display: flex;
                                    flex-direction: column;
                                    flex-wrap: wrap;
                                    align-content: flex-start;
                                }
                            }
                        }

                        &:hover {
                            visibility: visible;
                            opacity: 1;
                            display: flex;
                        }
                    }
                }
            }

            ul li:hover > ul,
            ul li ul:hover {
                visibility: visible;
                opacity: 1;
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                align-content: flex-start;
            }
        }
    }
}

// -- Mobile menu

.desktop-menu {
    display: block;

    @include md {
        display: none;
    }
}

.burger-menu {
    display: none;

    @include md {
        width: 30px;
        height: 30px;
        display: block;
        margin-left: 0.5em;
        padding: 0;
        border-bottom: 0 none;
        position: relative;
        right: 0;
        cursor: pointer;
        z-index: 101;
        transition: opacity 0.25s ease;

        &:hover {
            opacity: 0.7;
        }

        &.active {
            .top {
                background: $white;
                transform: translateY(11px) translateX(0) rotate(45deg);
            }

            .middle {
                opacity: 0;
            }

            .bottom {
                background: $white;
                transform: translateY(-9px) translateX(0) rotate(-45deg);
            }
        }

        span {
            background: black;
            border: none;
            height: 3px;
            width: 100%;
            position: absolute;
            top: 4px;
            left: 0;
            transition: all 0.35s ease;
            cursor: pointer;
            z-index: 101;

            &:nth-of-type(2) {
                top: 14px;
            }

            &:nth-of-type(3) {
                top: 24px;
            }
        }
    }
}

.overlay {
    position: fixed;
    background: $dark-grey;
    top: 0;
    left: 0;
    width: 100%;
    height: 0%;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.35s, visibility 0.35s, height 0.35s;
    // overflow: hidden;
    z-index: 100;

    &.open {
        opacity: 0.98;
        visibility: visible;
        height: 100%;

        li {
            animation: fadeInRight 0.5s ease forwards;
            animation-delay: 0.35s;

            &:nth-of-type(2) {
                animation-delay: 0.4s;
            }

            &:nth-of-type(3) {
                animation-delay: 0.45s;
            }

            &:nth-of-type(4) {
                animation-delay: 0.5s;
            }

            &:nth-of-type(5) {
                animation-delay: 0.55s;
            }

            &:nth-of-type(6) {
                animation-delay: 0.6s;
            }

            &:nth-of-type(7) {
                animation-delay: 0.65s;
            }

            &:nth-of-type(8) {
                animation-delay: 0.7s;
            }

            &:nth-of-type(9) {
                animation-delay: 0.75s;
            }

            &:nth-of-type(10) {
                animation-delay: 0.8s;
            }

            &:nth-of-type(11) {
                animation-delay: 0.85s;
            }

            &:nth-of-type(12) {
                animation-delay: 0.9s;
            }
        }
    }
    @include md {
        opacity: 0;
        visibility: hidden;
    }
}

.overlay-menu {
    display: block;
}

// -- Footer

.top-footer {
    background-color: $primary;
    padding: 1.5em 0;

    .container {
        padding: 20px 40px;

        @include xs {
            padding: 20px;
        }
    }

    .row {
        @include md {
            flex-direction: column;
            align-items: center;
            padding: 0;
        }
    }

    .column.left {
        display: flex;
        align-items: center;

        .logo {
            display: block;
            width: auto;
            height: 85px;

            @include md {
                height: 65px;
            }

            @include xs {
                height: 55px;
            }
        }
    }

    .column.right {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        flex-wrap: wrap;
        width: 100%;
        margin-left: 4em;

        @include md {
            margin-left: 0;
            justify-content: center;
            margin-top: 1em;
        }

        .footer-menu {
            text-align: left;

            @include md {
                min-width: 33.33%;
            }

            @include xs {
                min-width: 50%;
            }

            .footer-menu-bottom {
                margin-top: 1em;
            }

            .footer-menu-header {
                text-transform: uppercase;
                color: $primary;
                font-weight: 600;
            }

            ul {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;

                li {
                    list-style-type: none;
                    margin: 0 1em;

                    @include xs {
                        margin: 0 0.5em;
                    }

                    a {
                        color: $white;
                        text-decoration: none;
                    }
                }
            }
        }
    }
}

.bottom-footer {
    background-color: $dark-grey;
    color: $white;
    padding: 1em 0;
    display: flex;
    justify-content: center;

    @include md {
        text-align: center;
        flex-direction: column;
    }

    .credit-link {
        font-size: 0.75rem;
        font-weight: 400;
        color: $white;
        text-decoration: none;

        &.right {
            margin-left: 2em;

            @include xs {
                margin-left: 1em;
            }
        }

        @include md {
            margin-bottom: 0.5em;
        }
    }

    ul {
        display: flex;
        align-items: center;

        li {
            list-style-type: none;

            &.social-media-footer {
                display: flex;
                list-style-type: none;

                a img {
                    width: 20px;
                    height: 20px;
                }

                .facebook {
                    margin-right: 1em;
                }
            }
        }
    }
}

/* -- Global -- */

.page-title {
    color: $primary;
    margin: 0 0 0.5em;
    font-size: 2.5em;
    line-height: 55px;

    @include lg {
        font-size: 2.25em;
        line-height: 50px;
    }

    @include xs {
        font-size: 2.125em;
        line-height: 40px;
    }
}

.page-subtitle {
    font-weight: 400;
    line-height: 35px;
    font-size: 1.5rem;

    @include lg {
        font-size: 1.25em;
        line-height: 30px;
    }

    @include xs {
        line-height: 30px;
        font-size: 1.25rem;
    }
}

.centered-wrapper {
    padding: 4em 0;
    margin: 0 auto;
    max-width: 1240px;

    @include lg {
        max-width: 100%;
        padding: 4em 40px;
    }

    @include xs {
        max-width: 100%;
        padding: 2em 20px;
    }
}

.g-uppercase {
    color: inherit;
    font-weight: 400;
    letter-spacing: 0.05em;
    text-transform: uppercase;
}

.g-link {
    display: block;
    margin-top: 1.5em;
    color: $primary;
    font-weight: 600;
    text-decoration: none;

    &:hover {
        color: $secondary;
        width: fit-content;
    }
}

.column {
    &.text {
        text-align: left;
        margin-left: 4em;

        @include lg {
            margin-left: 2em;
        }

        @include md {
            margin-left: 0;
        }
    }
}

p {
    font-size: 1.125rem;
    line-height: 26px;

    @include xs {
        font-size: 1rem;
        line-height: 20px;
    }
}

h2 {
    line-height: 36px;
    font-size: 1.75rem;
    margin: 0.5em auto 1em;

    @include xs {
        line-height: 32px;
        font-size: 1.5rem;
    }
}

ol{
    margin-left: 1em;

    li{
        font-size: 1.125rem;
        margin: 1em 0;
        line-height: 26px;
    }
}

/* -- Homepage -- */

#home-heading {
    padding-bottom: 0;

    .home-heading-text {
        max-width: 900px;
        text-align: left;
        padding: 1em 0 0;
    }
}

#home-slider {
    height: 450px;
    margin: 3em auto 1em;
    padding: 0;

    @include lg {
        height: 350px;
    }

    @include xs {
        height: 250px;
    }

    .home-cms-slider {
        .slick-track {
            display: flex;
        }

        .slick-dotted.slick-slider {
            margin: 30px 0;
        }

        .slick-slide.slick-active {
            left: initial;

            &:before {
                background-color: transparent !important;
            }
        }

        .slick-slide {
            left: 0 !important;
            opacity: 1 !important;
            margin: 0 0 0 1em;
            width: 800px !important;
            height: 450px;

            @include lg {
                width: 600px !important;
                height: 350px;
            }

            @include xs {
                width: 400px !important;
                height: 250px;
            }

            // &:before {
            //     background-color: $primary !important;
            // }

            &:before {
                background-color: transparent !important;
            }

            figure {
                width: 800px !important;

                @include lg {
                    width: 600px !important;
                }

                @include xs {
                    width: 400px !important;
                }
            }

            .slide-image {
                opacity: 1;
                height: 450px;

                @include lg {
                    height: 350px;
                }

                @include xs {
                    height: 250px;
                }
            }
        }
        .slick-active {
            // margin: 0;
            height: auto;
            width: auto;
            opacity: 1;
            border-radius: 100%;
        }
    }
}

#home-about {
    position: relative;

    .centered-wrapper {
        padding: 4em 40px 8em;

        @include md {
            padding: 4em 40px;
        }

        @include xs {
            padding: 2em 20px;
        }
    }

    .home-about-text {
        text-align: left;
        max-width: 600px;
    }
}

.home-aes-img {
    position: absolute;
    bottom: -16em;
    z-index: 1;
    right: 0;
    max-width: 600px;

    @include xl {
        right: 25em;
    }

    @include md {
        position: static;
        width: 100%;
        margin: 2em auto 0;
    }
}

#home-services {
    position: relative;
    color: $dark-grey;
    background-color: $grey;
    padding: 5em 0;

    @include md {
        padding: 4em 0;
    }

    .centered-wrapper {
        padding-top: 12em;

        @include md {
            padding-top: 0;
        }
    }

    .home-services-text {
        text-align: left;
        max-width: 800px;
    }

    .home-services-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 2em;
        margin: 3em auto 0;

        @include lg {
            grid-template-columns: repeat(2, 1fr);
            gap: 1em;
            margin: 2em auto 0;
        }

        @include xs {
            grid-template-columns: repeat(1, 1fr);
            gap: 1em;
            margin: 2em auto 0;
        }

        .home-services-item {
            background: $primary;
            text-align: left;
            text-decoration: none;
            color: $white;
            background-image: linear-gradient(
                to left,
                transparent,
                transparent 50%,
                $secondary 50%,
                $secondary
            );
            background-position: 100% 0;
            background-size: 200% 100%;
            transition: all 0.25s ease-in;
            transition: all 0.3s ease-out;
            padding: 3em;

            &:hover {
                background-position: 0 0;
                color: #333;

                .home-services-arrow {
                    background-image: url("/img/arrow-green.svg");
                }
            }

            @include lg {
                padding: 2em;
            }

            .home-services-arrow {
                display: block;
                width: 30px;
                height: 30px;
                margin-bottom: 1em;
                background-position: center;
                background-image: url("/img/arrow-yellow.svg");
                background-size: cover;

                @include md {
                    width: 24px;
                    height: 24px;
                    margin-bottom: 0.75em;
                }
            }

            .home-services-item-text {
                h3 {
                    margin-bottom: 0.5em;
                    font-size: 1.35rem;
                    line-height: 27px;
                    z-index: 2;

                    @include xs {
                        margin-bottom: 0.25em;
                    }
                }
            }
        }
    }
}

#home-partners {
    background-color: $grey;
    padding: 4em 0;

    .column.text{
        width: 400px;

        @include md{
            width: 100%;
            margin: 0;
        }

        h2{
            line-height: 32px;
            font-size: 1.5rem;
        }
    }

    .home-partners-grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 1em;
        max-width: 1200px;
        margin: 0 auto;
        overflow: hidden; //temp
        margin-left: 2em;

        @include md{
            margin: 0;
        }

        @include xs{
            display: grid;
            grid-template-columns: repeat(3, 1fr);
        }

        a{
            width: 180px;
            height: 180px;

            @include xs {
                width: 120px;
                height: 120px;
            }

            img{
                width: 100%;
                object-fit: cover;
            }
        }
    }
}

#home-capabilities {
    .home-capabilities-img {
        @include md {
            width: 100%;
            margin-bottom: 2.5em;
        }
    }
}

/* -- CTA -- */

#cta {
    color: $white;
    background-color: $dark-grey;
    padding: 5em 0;

    @include xs {
        padding: 3em 0;
    }

    .container {
        max-width: 800px;

        @include xs {
            max-width: 100%;
        }
    }

    .button.primary {
        margin: 1em auto 0;
    }
}

#home #cta{
    display: none;
}

#home .cta-img {
    display: block;
    width: 100%;
    height: 500px;
    margin-bottom: -5px;
    object-fit: cover;
    object-position: center;

    @include md {
        height: auto;
    }
}

.cta-img {
    display: none;
}

// -- Contact Page

.contact-form-wrapper {
    padding: 2em 2em 2em 0;
    max-width: 800px;
    border-radius: 3px;
    background-color: $grey;

    @include lg {
        max-width: 100%;
        padding: 1.5em;
    }

    label {
        padding: 10px 0;
        font-size: 1rem;
    }

    .button.primary {
        margin: 1em 0;
    }
}

// -- CMS page

.cms-page {
    .container {
        margin: 0 auto;
    }

    .page-title {
        margin: 0.25em 0 0.5em;
    }
}

#cms-heading {
    min-height: 400px;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;

    .cms-heading-img {
        display: flex;
        justify-content: flex-end;
        margin: 1em 0;

        @include md {
            justify-content: center;
        }

        img {
            display: block;
            max-width: 600px;
            height: 400px;
            object-fit: cover;
            margin: 0;

            @include xs {
                width: 100%;
                height: 250px;
            }
        }
    }
}

.cms-text-wrapper {
    background-color: #e4e4e4;
    padding: 6em 0 4em;
    margin-top: -12em;

    @include lg {
        margin-top: -6em;
    }

    @include xs {
        margin-top: -12em;
    }
}

#cms-page-introduction {
    text-align: left;

    .cms-heading-text {
        max-width: 700px;
        padding: 1em 0 0;
    }
}

#cms-page-content {
    margin: 4em auto 0;

    @include xs {
        margin: 2em auto 0;
    }
}

.content-block {
    flex-direction: column;
    text-align: left;
    margin-bottom: 3em;

    &:last-child {
        margin-bottom: 0;
    }

    section {
        padding: 0;
    }

    &.cols-2 {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 4em;
        align-items: center;

        @include xs {
            grid-template-columns: repeat(1, 1fr);
            gap: 2em;
        }

        section {
            max-width: 50%;
            flex-basis: 50%;
            padding: 0;

            @include xs {
                max-width: 100% !important;
            }
        }
    }

    &.cols-3 {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 2em;
    }

    .text {
        h2 {
            font-weight: 600;
            font-size: 1.75em;
            line-height: 40px;
            margin: 0.5em auto;
        }

        p {
            line-height: 24px;
        }

        ul{
            margin-left: 1em;

            li{
                font-size: 1.125rem;
                line-height: 24px;
            }
        }

        br {
            line-height: 16px;
        }

        img {
            margin: 0;
            max-width: 100%;
        }

        blockquote {
            position: relative;
            background: #ededed;
            border-left: 8px solid $primary;
            padding: 2.5em;

            &:before {
                content: "";
                width: 30px;
                height: 30px;
                background-position: center;
                background-image: url("/img/quote-green.svg");
                background-size: cover;
                position: absolute;
                left: 26px;
                top: 20px;
            }

            &:after {
                content: "";
            }

            p {
                margin: 1em 0 0em 1em;
            }

            > blockquote {
                margin-top: 1em;
            }
        }

        table {
            table-layout: fixed;

            thead {
                tr {
                    background-color: $primary;
                    color: $white;
                }

                th {
                    font-weight: 400;
                    font-size: 1rem;
                    padding: 0.5em;
                }
            }

            tbody {
                tr {
                    &:nth-child(odd) {
                        background-color: #f7f7f7;
                    }

                    &:hover {
                        background-color: rgba(1, 80, 140, 0.5);
                    }
                }

                td {
                    padding: 0.5em;
                    color: $black;
                }
            }
        }
    }

    .accordion {
        width: 100%;

        .toggle {
            margin-bottom: 0.5em;
        }

        .toggle.active .title-name {
            background-color: $primary;
            color: $white;
        }

        .title-name {
            background-color: $grey;
            color: $dark-grey;
            border-radius: 0;
            padding: 0.75em 1em;
            font-weight: 400;
            font-size: 1rem;
        }

        .toggle-inner {
            padding-top: 1em;
        }
    }

    .gallery {
        ul {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 2px;

            @include xs {
                grid-template-columns: repeat(1, 1fr);
            }

            li {
                list-style-type: none;
            }
        }
    }

    .fr-file {
        margin-bottom: 1em;
        border-radius: 36px;
        border: 1px solid $black;
        padding: 0.25em 1em;
        text-decoration: none;
        color: $black;

        &:before {
            background-size: 20px 30px;
        }
    }
}

.content-block.cols-3 {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1em;

    section {
        max-width: 100%;
    }
}

#cms-cta {
    background-color: $dark-grey;
    color: $white;
    text-align: center;
    padding: 2em;

    .text {
        width: 100%;
        max-width: 800px;
        margin: 0 auto;
    }

    .button.primary {
        margin: 1em auto;
    }
}

// -- Testi slider

.testi-slider {
    height: 100%;
    padding: 0;

    .testi-slide {
        padding: 1em;
        margin: 0 1em;
        height: 22em;
        background-color: white;
        border-radius: 4px;
        transition: all 100ms ease-in-out;
        overflow: hidden;
        box-shadow: 0px 4px 12px rgb(17 17 26 / 3%),
            0px 6px 16px rgb(17 17 26 / 5%);
    }

    .testi-slide-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;

        p.name {
            margin-top: 0.5em;
        }
    }

    .slick-next,
    .slick-prev {
        background-color: transparent !important;
        color: transparent;
    }

    .slick-prev {
        left: -10px !important;
        z-index: 2;
        padding: 0;
        margin: 0;
    }

    .slick-next {
        right: -10px !important;
        z-index: 2;
        padding: 0;
        margin: 0;
    }

    button.slick-prev:before {
        background-image: url("/img/arrow-prev.svg");
        background-size: 20px 20px;
        display: inline-block;
        width: 20px;
        height: 20px;
        content: "";
    }

    button.slick-next:before {
        background-image: url("/img/arrow-next.svg");
        background-size: 20px 20px;
        display: inline-block;
        width: 20px;
        height: 20px;
        content: "";
    }

    .slick-slide {
        background-color: $primary;
        color: $white;
        &:before {
            background: transparent !important;
        }
    }

    .slick-dots {
        bottom: -32px;
        margin: 0;
        display: flex;
        width: 100%;
        justify-content: center;

        li {
            margin-left: 0.25rem;
            display: flex;
            height: auto;
            width: auto;
            border: 1px solid transparent;
        }

        button {
            background-color: lighten($dark-grey, 15%);
            border-radius: 0;
            margin: 0rem;
            background-size: 80%;
            background-repeat: no-repeat;
            background-position: center;
            text-indent: -9999px;
            overflow: hidden;
            opacity: 0.9;
            width: 0.4rem;
            height: 0.4rem;
            border-radius: 100%;

            &:hover {
                background-color: lighten($dark-grey, 20%);
            }
        }
    }
}
